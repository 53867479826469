// App.js
import React, { useState } from 'react';

const App = () => {
  const [inputText, setInputText] = useState('');
  const [flaskResponse, setFlaskResponse] = useState('');

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendRequest = async () => {
    try {
      const response = await fetch('https://mizobata-masahiro.net/process_text', {
        method: 'POST',
	mode: "cors",
	credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inputText }),
      });

      const data = await response.json();
      setFlaskResponse(data.response);
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };

  return (
    <div>
      <h1>React + Flask Sample App</h1>
      <input type="text" value={inputText} onChange={handleInputChange} />
      <button onClick={handleSendRequest}>Send to Flask</button>
      <p>Flask Response: {flaskResponse}</p>
    </div>
  );
};

export default App;

